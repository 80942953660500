// Smooth Scrolling: Smooth scrolls to an ID on the current page.
$(function() {
    $('a').bind('click', function(event) {
        var $anchor = $(this);
        $('html, body').stop().animate({
            scrollTop: $($anchor.attr('href')).offset().top
        }, 1500, 'easeInOutExpo');
        event.preventDefault();
    });
});

// Activates floating label headings for the contact form.
$(function() {
    $("body").on("input propertychange", ".floating-label-form-group", function(e) {
        $(this).toggleClass("floating-label-form-group-with-value", !!$(e.target).val());
    }).on("focus", ".floating-label-form-group", function() {
        $(this).addClass("floating-label-form-group-with-focus");
    }).on("blur", ".floating-label-form-group", function() {
        $(this).removeClass("floating-label-form-group-with-focus");
    });
});

// Closes the Responsive Menu on Menu Item Click
$('.navbar-collapse ul li a').click(function() {
    $('.navbar-toggle:visible').click();
});

// Scrollspy: Highlights the navigation menu items while scrolling.
$('body').scrollspy({
    target: '.navbar-fixed-top'
})

// Load WOW.js on non-touch devices
var isPhoneDevice = "ontouchstart" in document.documentElement;
$(document).ready(function() {
    if (isPhoneDevice) {
        //mobile
    } else {
        //desktop               
        // Initialize WOW.js
        const wow = new WOW({
            offset: 50
        })
        wow.init();
    }
})

window['greSubmit']=function(token) {

    $.ajax({
        url: "/submit",
        type: "POST",
        data: {
            'g-recaptcha-response': token,
            name: $("input#name").val(),
            phone: $("input#phone").val(),
            email: $("input#email").val(),
            type: $('input[name="type"]:checked').val(),
            message: $("textarea#message").val()
        },
        cache: false,
        success: function() {

            alert('Your message has been received. We will get back to you as soon as possible.');
            //clear all fields
            $('#contactForm').trigger("reset");
        },
        error: function() {

            alert('Failed to send your message, please try again later.');
            //clear all fields
            $('#contactForm').trigger("reset");
        }
    });
}


/*When clicking on Full hide fail/success boxes */
$('#name').focus(function() {
    $('#success').html('');
});


// Carousel
$('#carouselFade').carousel();
$('#logos').lightSlider({
    speed: 1500,
    item: 4,
    controls: false,
    pager:false,
    easing: 'linear',
    loop: true,
    responsive: [{
        breakpoint:600,
        settings: {item:2}
    },{
        breakpoint:400,
        settings: {item:1}
    }]
}).play();

$('a[href!="#tos"][href!="#privacy"]').click(function(){
    if(!$('#frontSlide').hasClass('activeSlide')) {
        $('.activeSlide').hide(0);
        $('.activeSlide').removeClass('activeSlide');
        $('#frontSlide').fadeIn(1500);
        $('#frontSlide').addClass('activeSlide');
    }
});

$('a[href="#tos"]').click(function(){
   $('.activeSlide').hide(0);
   $('.activeSlide').removeClass('activeSlide');
   $('#tosSlide').fadeIn(1500);
   $('#tosSlide').addClass('activeSlide');
});
$('a[href="#privacy"]').click(function(){
    $('.activeSlide').hide(0);
    $('.activeSlide').removeClass('activeSlide');
    $('#privacySlide').fadeIn(1500);
    $('#privacySlide').addClass('activeSlide');
});

// Cookie consent
window.cookieconsent.initialise({
    "palette": {
        "popup": {
            "background": "#000"
        },
        "button": {
            "background": "#f1d600"
        }
    },
    "theme": "edgeless"
});

// Load subpage
var hash = window.location.href.substring(window.location.href.indexOf("#")+1);
if(hash == 'tos' || hash == 'privacy') $('a[href="#'+hash+'"]').click();